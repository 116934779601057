<template>
    <v-dialog v-model="internalOpen" max-width="700">
        <div style="background-color: #FFFFFF; padding: 0 1em 0; position: relative;">
            <div class="py-4" style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <v-btn 
                    style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                    class="px-4 py-4"
                    @click.stop="cerrarModal" 
                    text
                    rounded
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </div>
            <div class="px-1 mt-1">
                <v-form ref="form">
                    <v-row dense>
                        <!-- Fecha-->
                        <v-col>
                            <v-menu transition="scale-transition" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-text-field
                                            v-model="fechas.fecha"
                                            :error-messages="fechaErrors"
                                            @click:clear="resetearFecha"
                                            append-icon="mdi-calendar"
                                            clearable
                                            label="Fecha "
                                            outlined
                                            placeholder="Seleccione una fecha"
                                            readonly
                                            @change="$v.form.fecha.$touch()"
                                            @blur="$v.form.fecha.$touch()"
                                        />
                                    </span>
                                </template>
                                <div @click.stop>
                                    <vc-date-picker
                                        v-model="form.fecha"
                                        mode="date"
                                        locale="es"
                                    />
                                </div>
                            </v-menu>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="form.numero_documento"
                                label="Número de documento"
                                placeholder="Número de documento"
                                clearable
                                outlined
                                :error-messages="numero_documentoErrors"
                                @change="$v.form.numero_documento.$touch()"
                                @blur="$v.form.numero_documento.$touch()"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.nombre_proveedor"
                                label="Nombre de proveedor"
                                placeholder="Nombre de proveedor"
                                clearable
                                outlined
                                :error-messages="nombre_proveedorErrors"
                                @change="$v.form.nombre_proveedor.$touch()"
                                @blur="$v.form.nombre_proveedor.$touch()"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                v-model="form.descripcion"
                                label="Descripción"
                                placeholder="Descripción"
                                outlined
                                clearable
                                no-resize
                                rows="3"
                                :error-messages="descripcionErrors"
                                @change="$v.form.descripcion.$touch()"
                                @blur="$v.form.descripcion.$touch()"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field 
                                label="Monto retenido" 
                                required 
                                type="number"
                                v-model="form.monto_retenido"
                                outlined
                                light
                                :error-messages="monto_retenidoErrors"
                                @change="$v.form.monto_retenido.$touch()"
                                @blur="manejarMontoRetenidoBlur"
                                style="width: 100%"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field 
                                label="Monto total" 
                                required 
                                type="number"
                                v-model="form.monto_total"
                                outlined
                                light
                                :error-messages="monto_totalErrors"
                                @change="$v.form.monto_total.$touch()"
                                @blur="manejarMontoTotalBlur"
                                style="width: 100%"
                            />
                        </v-col>
                    </v-row>

                    <div 
                        class="d-flex justify-center align-center py-4" 
                        style="gap: 16px; position: sticky; bottom: 0; background-color: #FFFFFF;"

                    >
                        <v-btn 
                            @click.stop="cerrarModal" 
                            class="flex-grow-1 flex-shrink-1" 
                            color="secondary"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            @click.stop="enviar"
                            class="flex-grow-1 flex-shrink-1" 
                            color="primary"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </v-form>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { required, minValue } from 'vuelidate/lib/validators';
import { Validator } from '@/utils/form-validation';
import { isNil } from 'lodash';
import moment from 'moment';

export default {
    name: 'EditarRegistroCompraCentroEscolar',
    emits: ['on-visibility-change', 'on-save'],
    validations: () => ({
        form: {
            fecha: { required },
            numero_documento: { required },
            nombre_proveedor: { required },
            descripcion: { required },
            monto_retenido: { required, minValue: minValue(0)  },
            monto_total: { required, minValue: minValue(0.01) },
        },
    }),
    props: {
        isOpen: { type: Boolean },
        registro: { type: Object },
    },
    data: () =>({
        internalOpen: false,
        fechas: {
            fecha: null,
        },
        form: {
            fecha: null,
            numero_documento: null,
            nombre_proveedor: null,
            descripcion: null,
            monto_retenido: null,
            monto_total: null,
        },
    }),
    computed: {
        fechaErrors() {
            return new Validator(this.$v.form.fecha).detect().getResult();
        },
        numero_documentoErrors() {
            return new Validator(this.$v.form.numero_documento).detect().getResult();
        },
        nombre_proveedorErrors() {
            return new Validator(this.$v.form.nombre_proveedor).detect().getResult();
        },
        descripcionErrors() {
            return new Validator(this.$v.form.descripcion).detect().getResult();
        },
        monto_retenidoErrors() {
            return new Validator(this.$v.form.monto_retenido).detect().getResult();
        },
        monto_totalErrors() {
            return new Validator(this.$v.form.monto_total).detect().getResult();
        },
        // Campos formulario
        fechaRegistro() {
            return this.form.fecha;
        },
    },
    methods: {
        resetearFecha() {
            this.form.fecha = null;           
        },
        fechaFormatted(fecha) {
            return fecha ? moment(fecha).format("DD-MM-YYYY") : '';
        },
        limpiarFormulario() {
            this.$v.form.$reset();
            this.$refs.form.reset();
        },
        enviar() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            const dataTransformada = {
                ...this.form,
                monto_retenido: Number(this.form.monto_retenido),
                monto_total: Number(this.form.monto_total),
            };
            
            this.$emit('on-save', dataTransformada);
            this.limpiarFormulario();
        },
        cerrarModal() {
            this.internalOpen = false;
            this.limpiarFormulario();
        },
        manejarMontoRetenidoBlur() {
           this.$v.form.monto_retenido.$touch();
           const value = this.form.monto_retenido;

           if (!value) return;

           this.form.monto_retenido = Number(value).toFixed(2);
        },
        manejarMontoTotalBlur() {
           this.$v.form.monto_total.$touch();
           const value = this.form.monto_total;

           if (!value) return;

           this.form.monto_total = Number(value).toFixed(2);
        },
    },
    watch: {
        isOpen(value) {
            if (isNil(value)) return;

            this.internalOpen = value;
        },
        internalOpen(value) {
            this.$emit('on-visibility-change', value);
        },
        registro(value) {
            if (!value) return;

            this.form.nombre_proveedor = value.nombre_proveedor;
            this.form.numero_documento = value.numero_documento;
            this.form.monto_retenido = value.monto_retenido;
            this.form.monto_total = value.monto;
            this.form.descripcion = value.observacion;
            this.form.fecha =  moment(value.fecha).toDate();
        },
        fechaRegistro(value) {
            this.fechas.fecha = this.fechaFormatted(value);
        },
    },
}
</script>